import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import './Feedback.css';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import backendUrl from "../config";


export default function FeedBack() {
    
    const navigate = useNavigate();

    const submitForm = () => {
        const f_name = document.querySelector('#first-name').value
        const l_name = document.querySelector('#last-name').value
        const rating = document.querySelector('#rating').value
        const email = document.querySelector('#email').value
        const feedback = document.querySelector('#feedback').value

        // Check if any of the fields are empty
        if (!f_name || !l_name || !rating || !email || !feedback) {
            alert('Please fill in all fields before submitting.');
        } else {
            const feedbackData = {
                name: f_name + ' ' + l_name,
                email: email,
                ratings: rating,
                comment: feedback
            }

            axios.post(backendUrl + '/post_feedback/', feedbackData, {
                headers: {
                  'Content-Type': 'application/json',
                },
              })
                .then(response => {
                  if (response.data.status === 200) {
                    navigate('/');
                  } else if (response.data.status === 400) {
                    alert('Email already exists.');
                  }
                })
                .catch(error => {
                  console.error(error);
                  alert('Something Went Wrong, Kindly try again later!');
                });
              
            }
    }

    return (
        <div className="feedback-page">
            <Navbar/>
                <div className="feedback-section">
                    <div className="container feedback">
                        <div className=" py-5 h-100">
                            <div className="row d-flex justify-content-center align-items-center h-100">
                                <div className="col">
                                    <div className="card card-registration my-4">
                                        <div className="row g-0">
                                            <div className="col-xl-6 d-none d-xl-block">
                                            <img src="./static/images/Frame-7.jpg"
                                                alt="Form-image" className="img-fluid"
                                                />
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="card-body p-md-5 text-black">
                                                    <h3 className="mb-5 text-uppercase f-2">Feedback Form</h3>

                                                    <div className="row">
                                                        <div className="col-md-6 mb-4">
                                                            <div className="form-outline">
                                                            <input type="text" id="first-name" className="form-control form-control-lg" />
                                                            <label className="form-label f-3" for="first-name">First name</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mb-4">
                                                            <div className="form-outline">
                                                            <input type="text" id="last-name" className="form-control form-control-lg" />
                                                            <label className="form-label f-3" for="last-name">Last name</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6 mb-4">
                                                            <label className="form-label f-3" for="ratings">Rating</label>
                                                            <select id="rating" className="select f-3 mx-3 px-4">
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-outline mb-4">
                                                        <input type="text" id="email" class="form-control form-control-lg" />
                                                        <label class="form-label f-3" for="email">Email ID</label>
                                                    </div>

                                                    <div className="form-outline mb-4">
                                                        <textarea className="form-control comment" id="feedback" type="text" placeholder="Message" data-sb-validations="required"></textarea>
                                                        <label className="form-label f-3" for="feedback">Message</label>
                                                    </div>

                                                    <div className="d-flex justify-content-end pt-3">
                                                        <button className="f-3" onClick={submitForm}><span>Submit</span>
                                                            <div className="animation-box"></div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer/>
        </div>
    )
    
}