import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();

  const [navbarStyle, setNavbarStyle] = useState({ backgroundColor: '', top: '0px' });
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isMenuExpanded, setIsMenuExpanded] = useState(true);

  let isOnMainPage = useRef()

  useEffect(() => {
      isOnMainPage.current = location.pathname === '/';

    // Add a scroll event listener to update the navbar style
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY >= 0 && scrollY < 100) {
        setNavbarStyle({ backgroundColor: '', top: '0px' });
      } else {
        setNavbarStyle({ backgroundColor: '#111111', top: '0px' });
      }

      if (scrollY >= 0 && scrollY < 100) {
        setNavbarStyle((prevStyle) => ({ ...prevStyle, top: '0px' }));
      } else if (lastScrollY < scrollY) {
        setNavbarStyle((prevStyle) => ({ ...prevStyle, top: '-120px' }));
      } else {
        setNavbarStyle((prevStyle) => ({ ...prevStyle, top: '0px' }));
      }

      // Update lastScrollY
      setLastScrollY(scrollY);
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname, lastScrollY]);

  const navLinks = () => {
    // Navigate to the Landing Page if it is not the landing page
    if (!isOnMainPage.current) {
      navigate('/');
    }
  };

  const toggleMenu = () => {
      console.log(isMenuExpanded);
      if (isMenuExpanded) {
            setNavbarStyle({ height: 'auto'});
            setIsMenuExpanded(false)
        } else {
            setNavbarStyle({ height: '100px'});
            setIsMenuExpanded(true)
        }
  };

  const scrollToSection = (sectionId) => {
    toggleMenu()
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={`section navbar-section ${isMenuExpanded ? 'expanded' : ''}`} style={navbarStyle}>
      <div className="container navbar justify-content-between">
        <div className="small-navbar"> 
          <div className="logo">
            <img onClick={navLinks} src="./static/logo.png" alt="Logo" />
          </div>
          <div className="nav-icon s-screen" onClick={toggleMenu}>
            <img id="nav-click" src="./static/icon/nav-icon.svg" alt="Nav Icon" />
          </div>
        </div>
        <div className="navbar-links f-3 d-flex">
          <ul>
          {isOnMainPage.current ? (
            <>
                <li><a onClick={() => scrollToSection('home-section')}>HOME</a></li>
                <li><a onClick={() => scrollToSection('about-section')}>ABOUT US</a></li>
                <li><a onClick={() => scrollToSection('services-section')}>SERVICES</a></li>
                <li><a onClick={() => scrollToSection('cta-section')}>CONTACT</a></li>
            </>
            ) : (
            <>
                <li><a onClick={navLinks} >HOME</a></li>
                <li><a onClick={navLinks}>ABOUT US</a></li>
                <li><a onClick={navLinks}>SERVICES</a></li>
                <li><a onClick={navLinks}>CONTACT</a></li>
            </>
            )}
                
          </ul>
          <a href="https://wa.me/+971562046736" target="_blank">
            <button className="wide-screen">
              <span>Call US</span>
              <div className="animation-box"></div>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
