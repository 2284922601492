import { useNavigate } from 'react-router-dom';
import backendUrl from "../config";


export default function Footer() {

    const navigate = useNavigate();

    const loginPage = () => {
        window.location.href = backendUrl+'/admin/';
    }
    
    const Feedback = () => {
        navigate('/feedback');
    }

    return (
        <div className="section footer-section">
            <div className="container footer">
                <img src="./static/logo.png"/>
                <div className="grid-container c-white">
                    <div className="grid-item">
                        <span className="f-3">Your Reliable Partner for UAE Relocations - Smooth Moves, Every Time.</span>
                        <span className="f-copyright">Copyright © 2023 MovingPacker.</span>
                    </div>
                    <div className="grid-item">
                        <div className="contact">
                            <div className="icon">
                                <span className="f-3 mx-2">contact@movingpacker.co</span>
                                <img src="./static/icon/email.svg"/>
                            </div>
                            <div className="icon my-2">
                                <span className="f-3 mx-2">+971562046736</span>
                                <img src="./static/icon/mobile.svg"/>
                            </div>
                        </div>
                        <ul className="">
                            <li><a onClick={loginPage}>Login</a></li>
                            <li><a onClick={Feedback}>Feedback</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}