import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ReviewCard from '../components/ReviewCard'
import Advertisement from '../components/Advertisement';
import axios from 'axios';
import backendUrl from "../config";


import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


export default function LandingPage(){
    const [reviews, setReviews] = useState([]);
    const [adds, setAdds] = useState([]);
    const [imageContainer, setImageContainer] = useState(true);
    
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
      const apiUrl = backendUrl+'/get_feedback/'; 
      console.log(apiUrl);
        axios.get(apiUrl)
            .then((response) => {
                setReviews(response.data.feedback); // Set the reviews data in state
            })
            .catch((error) => {
                console.error(error);
            });

        axios.get(backendUrl+'/get_image')
            .then((response) => {
                setAdds(response.data.advertisements)
                console.log(response.data.advertisements);

                if (response.data.advertisements.length === 0) {
                    setImageContainer(false)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []); // The empty array

    return(
        <div className="landingpage">
            <Navbar />
            <div id="home-section" className="section hero-section d-flex align-items-center">
                <img src="./static/images/box-icon.png"/>
                <img src="./static/images/box-icon.png"/>
                <img src="./static/images/box-icon.png"/>
                <img src="./static/images/box-icon.png"/>
                <img src="./static/images/box-icon.png"/>
                <img src="./static/images/box-icon.png"/>
                <img src="./static/images/box-icon.png"/>
                <img src="./static/images/box-icon.png"/>
                <img src="./static/images/box-icon.png"/>
                <img src="./static/images/box-icon.png"/>
                <div className="container hero c-white d-flex flex-column align-items-center">
                    <h1 className="f-1">Let Us Handle Your <span className="c-theme">Moving</span></h1>
                    <span className="f-2">Experience a Stress-Free Move with Our Expertise <span className="c-theme">Across UAE</span>.</span>
                    <button id="to-cta" className="f-3" onClick={() => scrollToSection('cta-section')}><span>Schedule Your Move</span>
                        <div className="animation-box"></div>
                    </button>
                </div>
            </div>
            <div className="section about-section">
                <div id="about-section" className="container about">
                    <div className="grid-container">
                        <div className="grid-item">
                            <span className="f-2 c-theme">About Us</span>
                            <h1 className="f-4">Who Are We?</h1>
                            <span className="f-3 c-grey">At Moving Packer, we take pride in our commitment to 
                                providing top-notch moving services in the UAE. Our mission
                                is to make your move as smooth and stress-free as possible. 
                                With a focus on customer satisfaction and a vdedication to excellence, 
                                we've become a trusted name in the industry.
                                </span>
                        </div>
                        <div className="grid-item d-flex justify-content-end  ">
                            <div className="image wide-screen">
                                <img src="./static/images/Frame-1.jpg"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section services-section">
                <div id="services-section" className="container services">
                    <div className="section-heading">
                        <span className="f-2 c-theme">Services</span>
                        <h1 className="f-4">What We Offer</h1>
                    </div>
                    <div className="grid-container">
                        <div className="grid-item">
                            <img src="./static/images/Frame-3.png"/>
                            <span className="f-2">Long-Distance Moving</span>
                            <span className="f-3 c-grey">Moving across cities or states? We've got 
                                you covered with our reliable long-distance moving services.
                            </span>
                        </div>
                        <div className="grid-item">
                            <img src="./static/images/Frame-4.png"/>
                            <span className="f-2">Office Moving</span>
                            <span className="f-3 c-grey">Minimize downtime and disruptions during 
                                your office move with our efficient office moving solutions.
                            </span>
                        </div>
                        <div className="grid-item c-white">
                            <span className="f-4">Custom Moving</span>
                            <span className="f-3">Have unique moving needs? Contact us to discuss 
                                your custom moving requirements.
                            </span>
                            <button id="to-cta"><span className="f-3" onClick={() => scrollToSection('cta-section')}>Lets Talk</span>
                                <div className="animation-box"></div>
                            </button>
                        </div>
                        <div className="grid-item">
                            <img src="./static/images/Frame-5.png"/>
                            <span className="f-2">Home Moving</span>
                            <span className="f-3 c-grey">Trust us to handle your precious belongings 
                                with care during your residential move.
                            </span>
                        </div>
                        <div className="grid-item">
                            <img src="./static/images/Frame-6.png"/>
                            <span className="f-2">Packing and Moving</span>
                            <span className="f-3 c-grey">Let us handle the packing and moving process, 
                                ensuring your belongings are securely packed and safely transported 
                                to your new destination.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section how-section">
                <div className="container how">
                    <div className="section-heading">
                        <span className="f-2 c-theme">Discover the Ease of Our Process</span>
                        <h1 className="f-4">Streamlined Moving Process</h1>
                    </div>
                    <div className="grid-container">
                        <div className="grid-item">
                            <div className="icon-container">
                                <img src="./static/icon/icon-1.svg"/>
                            </div>
                            <span className="f-2">Estimation</span>
                            <span className="f-3 c-grey">Kickstart your move with an estimation session.</span>
                        </div>
                        <div className="grid-item">
                            <div className="icon-container">
                                <img src="./static/icon/icon-2.svg"/>
                            </div>
                            <span className="f-2">Personalized Plan</span>
                            <span className="f-3 c-grey">Crafting a custom plan and quote just for you.</span>
                        </div>
                        <div className="grid-item">
                            <div className="icon-container">
                                <img src="./static/icon/icon-3.svg"/>
                            </div>
                            <span className="f-2">Smooth Relocation</span>
                            <span className="f-3 c-grey">Sit back and relax as our expert team handles every aspect of your move.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section testimonials-section">
                <img src="./static/images/gradient.png"/>
                <img src="./static/images/gradient.png"/>
                <div className="container testimonials d-flex flex-column align-items-center">
                    <div className="section-heading">
                        <span className="f-2 c-theme">Client Testimonials</span>
                        <h1 className="f-4">Read What Our<br/>Customers Say</h1>
                    </div>
                    <div className="cards-container">
                        <Swiper
                            modules={[Navigation]}
                            slidesPerView={1}
                            spaceBetween={10}
                            navigation
                            breakpoints={{
                                900: {
                                  slidesPerView: 3,
                                  spaceBetween: 30,
                                },
                              }}
                        >
                            {reviews.map((review) => (
                                <SwiperSlide>
                                    <ReviewCard
                                        name={review.name}
                                        comment={review.comment}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
            {imageContainer ? (
            <div className="section advertisements-section">
                <div className="container advertisement">
                <Swiper
                    modules={[Pagination]}
                    slidesPerView={1}
                    spaceBetween={10}
                    pagination={{
                        clickable: true,
                    }}
                >
                    {adds.map((imageURL) => (
                        <SwiperSlide>
                            <Advertisement 
                                url={imageURL.image}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
                </div>
            </div> ):''}

            <div id="cta-section" className="section cta-section">
                <div className="container cta d-flex flex-column justify-content-center align-items-center">
                    <img src="./static/images/cta.png"/>
                    <h1 className="f-5">Start Your Move with Confidence - Contact Us Today.</h1>
                    <div className="cta-btns">
                        <a href="mailto:contact@movingpacker.co"><button className="f-3"><span>Email Us</span>
                            <div className="animation-box"></div>
                        </button></a>
                        <a href="https://wa.me/+971562046736" target="_blank"><button className="f-3"><span>Call Us</span>
                            <div className="animation-box"></div>
                        </button></a>
                    </div>
                </div>
            </div>
            <Footer />
            <a className='phone' href="https://wa.me/+971562046736" target='_blank'><img className='whatsapp-icon' src='/static/icon/whatsapp.png'/></a>
        </div>
    )
}