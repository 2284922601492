
export default function ReviewCard(props) {
    
    return (
        <div className="card f-3 c-grey">
            <div className="feedback">
                {props.comment}
            </div>
            <div className="name d-flex">
                <div className="icon">
                    <img src="./static/icon/icon-4.svg"/>
                </div>
                <span className="mx-3">{props.name}.</span>
            </div>
        </div>
    )
}